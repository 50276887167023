import React from "react"

import SEO from "../components/seo"
import Hero from "../components/home/Hero"
import "./index.css"
import FeatureSection from "../components/home/FeatureSection"
import Footer from "../components/common/Footer"
import CallToActionSection from "../components/home/CallToActionSection"

const IndexPage = () => (
  <>
    <SEO
      pageTitle="Home"
      ogTitle="Fundraisify, simple online fundraising"
      description="Partner with a small business to start fundraising online, for free."
    />
    <main className="bg-gray-100">
      <Hero />
      <FeatureSection />
      <CallToActionSection />
      <Footer />
    </main>
  </>
)

export default IndexPage
