import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Navbar from "../common/Navbar"

const Hero = () => {
  const hero = useStaticQuery(graphql`
    query HeroQuery {
      sanityHome(title: { eq: "Landing" }) {
        image {
          asset {
            fluid(maxWidth: 750) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)

  return (
    <div className="relative overflow-hidden bg-indigo-800">
      <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
        <div className="relative h-full max-w-screen-xl mx-auto">
          <svg
            className="absolute transform right-full translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="svg-pattern-squares-1"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-indigo-900"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#svg-pattern-squares-1)" />
          </svg>
          <svg
            className="absolute transform left-full -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="svg-pattern-squares-2"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-indigo-600"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#svg-pattern-squares-2)" />
          </svg>
        </div>
      </div>

      <div className="relative">
        <Navbar />
        <div className="flex items-center justify-center pb-8 mt-3 lg:justify-between lg:mt-0 lg:pb-0">
          <div className="max-w-2xl mx-4 text-center md:ml-8 lg:ml-24 xl:ml-32">
            <h2 className="text-4xl font-extrabold leading-10 tracking-tight text-gray-100 sm:text-5xl sm:leading-none md:text-6xl">
              Jumpstart your
              <br />
              <span className="text-indigo-400 xl:pl-3">online fundraiser</span>
            </h2>
            <p className="max-w-md mx-auto mt-3 text-base text-gray-50 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Where small businesses and community campaigns meet. Start raising
              money online today by teaming up with one or more of our local
              small business partners.
            </p>
            <div className="max-w-md mx-auto mt-5 sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <Link
                  to="/contact"
                  className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo md:py-4 md:text-lg md:px-10"
                >
                  Get started for free
                </Link>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <Link
                  to="/demo"
                  className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-indigo-600 transition duration-150 ease-in-out bg-white border border-transparent rounded-md hover:text-indigo-500 focus:outline-none focus:shadow-outline-indigo md:py-4 md:text-lg md:px-10"
                >
                  Live demo
                </Link>
              </div>
            </div>
          </div>
          <Img
            className="hidden lg:block"
            style={{ width: "650px" }}
            fluid={hero.sanityHome.image.asset.fluid}
            alt="Hot air balloon"
          />
        </div>
      </div>
    </div>
  )
}
export default Hero
