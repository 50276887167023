import React from "react"
import { Link } from "gatsby"

const CallToActionSection = () => {
  return (
    <div className="bg-indigo-800">
      <div className="max-w-screen-xl px-4 py-20 mx-auto sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-50 sm:text-4xl sm:leading-10">
          Ready to dive in?
          <br />
          <span className="text-indigo-400">Get started today.</span>
        </h2>
        <div className="flex mt-8 lg:flex-shrink-0 lg:mt-0">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:shadow-outline"
            >
              Get started for free
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallToActionSection
